import axios from "axios"
export default {
  state: {
    stat: {
      G6_USERS: [],
      GRAPHICS: {
        G1: [],
        G2: [],
        G3: [],
        G4: [],
        G5: [],
        G6: [],
  
      }
    },
    stat2: {
      direct: {
        in: null,
        success: null,
        wait: null
      }
    },
    marketolog: [],
    logists: [], 
    bxusers: []
  },
  mutations: {
    SET_STAT: (s, stat) => {s.stat = stat},
    SET_STAT2: (s, stat) => {s.stat2 = stat},
    SET_LOGISTS: (s, stat) => {s.logists = stat},
    SET_USERS: (s, users) => {s.bxusers = users},
  },
  actions: {
    async getMarketolog({state}, period) {
      const {data} = await axios.post("getMarketolog", {period}); 
      state.marketolog = data; 
    },
    async getStat({commit}, period) {
      const {data: stat} = await axios.post("getStat", {period}); 
      commit("SET_STAT", stat);  
    },
    async getStat2({commit}, period) {
      const {data: stat} = await axios.post("getStat2", {period}); 
      commit("SET_STAT2", stat);   
    },
    async statLogists({commit}, period) {
      const {data: stat} = await axios.post("statLogists", {period}); 
      commit("SET_LOGISTS", stat);  
    },
    async bxUsers({commit}) {
      const {data: users} = await axios.post("bxUsers");
      commit("SET_USERS", users);
    }
  },
  getters: {
    stat: s => s.stat,
    stat2: s => s.stat2,
    logists: s => s.logists,
    bxusers: s => s.bxusers,
    marketolog: s => s.marketolog,
  }
}