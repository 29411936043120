import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import router from "../router/index.js";
import stat from "./stat"
Vue.use(Vuex)


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        deals: [],
        total: 0,
        sum: [],
        logistList: [],
        user: '',
        loading: false,
        saving: false,
        users: [],
        users_sum: null,
        s: [],
        transactions: [],
        reload: false,
        interval: null
    },
    mutations: {
        AUTH_SUCCESS: (s, token) => {
            s.token = token;
            localStorage.setItem("token", token);
        },
        SET_DEALS: (s, data) => {
            s.user = data.user;
            s.total = data.total;
            s.deals = data.deals;
            s.sum = data.sum;
            s.loading = false;
            s.logistList = data.logistList;
        },
        SET_LOADING: s => s.loading = true,
        SET_SAVING: s => s.saving = !s.saving,
        SET_USERS: (s, users) => {
            s.users = users;
            s.loading = false;
        },
        SET_TRANSACTIONS: (s, t) => s.transactions = t
    },
    actions: {
        async authSubmit({ commit }, payload) {
            const { data } = await axios.post("login", payload);
            if (data.success) {
                commit("AUTH_SUCCESS", data.token)
                router.push("/")
            }
        },
        async getReport({ commit }, filter) {
            commit("SET_LOADING");
            const { data } = await axios.post("report", { filter });
            commit("SET_DEALS", data);
        },
        async createReport({ state, commit }, filter) {
            commit("SET_SAVING");
            const { data } = await axios.post("createReport", { filter, total: state.total, sum: state.sum[1] });
            commit("SET_SAVING");
            var fileLink = document.createElement('a');
            fileLink.href = data.url;
            if (state.user != "Администратор")
                fileLink.setAttribute('download', 'report.docx');
            else
                fileLink.setAttribute('download', data.url.split("/")[data.url.split("/").length - 1]);
            document.body.appendChild(fileLink);
            fileLink.click();
        },
        async createAct({ commit, dispatch }, params) {
            commit("SET_SAVING");
            const { data: result } = await axios.post("createAct", params);
            commit("SET_SAVING");
            dispatch("download", result.url);
        },
        async createActFromBank({ commit, dispatch }, params) {
            commit("SET_SAVING");
            const { data: result } = await axios.post("createActFromBank", params);
            commit("SET_SAVING");
            dispatch("download", result.url);
        },
        async download(_, url) {
            var fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.setAttribute('download', url.split("/")[url.split("/").length - 1]);
            document.body.appendChild(fileLink);
            fileLink.click();
        },
        logout() {
            localStorage.setItem("token", '');
            location.href = "/";
        },
        async getUsers({ commit, state }, period) {
            commit("SET_LOADING");
            const { data: users } = await axios.post("users", { period });
            commit("SET_USERS", users);
        },
        async getUsersSum({state, dispatch}, period) {
            state.users_sum = null; 
            state.reload = false;
            const {data} = await axios.post('createStatement', {period}); 
            state.interval = setInterval(async function() {
                console.log('interval')
                if(state.users_sum || state.reload) { 
                    clearInterval(state.interval);
                    return true; 
                }
                await dispatch('getStatement', {statementId: data.statementId, period});
            }, 3000)
        },
        async getStatement({state}, {statementId, period}) {
            await sleep(2000); 
            const {data} = await axios.post('getUserStatement', {statementId, users: state.users, period});  
            if(data.status) {
                state.users_sum = data.users;
                for(let user in state.users) {
                    state.users[user].bank = state.users_sum[state.users[user].id];  
                }
            }  
        },
        async sendPayment(_, params) {
            const { data: result } = await axios.post("sendPayment", params);
        },
        async getTransactions({ commit }, page) {
            const { data: result } = await axios.post("getTransactionList", { page });
            commit("SET_TRANSACTIONS", result);
        },
        async statementInit() {
            const { data: result } = await axios.post("statementInit");
        },
        async addUser(_, params) {
            const { data: result } = await axios.post("addUser", params);
            location.reload();
        },
        async userDelete(_, id) {
            if (!confirm("Удалить пользователя?")) return false;
            await axios.post("userDelete", { id });
            location.reload();
        },
        async userEditStore(_, params) {
            await axios.post("userEdit", params);
        }
    },
    getters: {
        isLogged: s => !!s.token,
        deals: s => s.deals,
        user: s => s.user,
        total: s => s.total,
        sum: s => s.sum,
        loading: s => s.loading,
        logistList: s => s.logistList,
        saving: s => s.saving,
        users: s => s.users,
        s: s => s.s,
        transactions: s => s.transactions,
        users_sum: s => s.users_sum
    },
    modules: {
        stat
    }
})