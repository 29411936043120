import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Moscow')
Vue.config.productionTip = false

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token') || '';
    if (token) config.headers["Authorization"] = "Bearer " + token;
    config.headers["Content-Type"] = "application/json";
    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (401 === error.response.status) {
        return store.dispatch("logout");
    }
    return Promise.reject(error);
});

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = 'https://docs.rosperevozki.ru/api/';


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')