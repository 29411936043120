import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"

Vue.use(VueRouter)

const routes = [
    {
      path: '/main',
      name: 'Main',
      component: () => import('../views/Main.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () =>
            import ("../views/Home.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/auth',
        name: "Auth",
        component: () =>
            import ("../views/Auth.vue")
    },
    {
        path: '/users',
        name: "Users",
        component: () =>
            import ("../views/Users.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/bank',
        name: "Bank",
        component: () =>
            import ("../views/Bank.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/acts',
        name: "Acts",
        component: () =>
            import ("../views/Acts.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/marketolog',
        name: "Marketolog",
        component: () => import ("../views/Marketolog.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stat2',
        name: "Stat2",
        component: () =>
            import ("../views/Stat2.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stat',
        name: "Stat",
        component: () =>
            import ("../views/Stat.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stat/logist',
        name: "Stat Logist",
        component: () =>
            import ("../views/StatLogist.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ttbot',
        name: 'TrustTrans Bot',
        component: () => import('../views/TrustBot.vue'),
        meta: {
            requiresAuth: true
        }
    }
 
    
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const requireAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requireAuth && !store.getters.isLogged)
        return next('/auth');
    if (to.path == "/auth" && store.getters.isLogged)
        return next("/")
    next();
})

export default router